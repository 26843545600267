/* Card.css */

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 12px;
  margin: 5px;
  width: 400px;
  height: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #666666;
  cursor: pointer;
}

.card-title {
  font-size: 20px;
  margin-bottom: 8px;
  color: #0913b1;
  margin-top: 5px;
  text-align: center;
}

.card-photo {
  width: 100%;
  margin-bottom: 8px;
  max-height: 250px;
  object-fit: contain;
}

@media (max-width: 760px) {
  .card {
    padding: 10px;
    margin: 20px;
    width: 85%;
    height: auto;
  }

  .card-photo {
    width: 100%;
  }
}
