.main-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-top: 80px;
  width: 100%;
}

.top-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.logoFooter {
  width: 100%;
  justify-content: center;
}

.nav-link {
  text-decoration: none;
  color: #0913b1;
  padding: 10px;
  font-weight: bolder;
  /* transition: color 0.3s ease, background-color 0.3s ease; */
}

.nav-link:hover {
  color: gray;
}

.nav-link.active {
  color: white;
  background-color: blue;
  border-radius: 5px;
}
.adresse-footer {
  background-image: url("../images/Footer/footer-localisation-icone.png");
  background-repeat: no-repeat;
  background-position: left;
}

.adresse-footer p {
  display: flex;
  padding-left: 32px;
  color: #666;
  margin-bottom: 15px;
  text-align: center;
}

.tel-hor {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.tel-hor p {
  display: flex;
  color: #0913b1;
}

.reseaux-sociaux {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.reseaux-sociaux-icones {
  display: flex;
}

.reseaux-sociaux-icones img {
  margin-left: 12px;
}

.liens-footer {
  border-top: 1px solid #b8bcef;
  width: 90%;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.link-footer {
  text-decoration: none;
  font-weight: light;
  font-size: 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

link-footer:hover {
  color: gray;
}

.titres-liens ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 12px;
  list-style: none;
}

.titres-liens {
  flex: 1 1 auto;
}

.titres-liens li {
  margin-right: 40px;
}

.titres-liens li.btn {
  text-decoration: none;
}

.mentions-legales {
  margin-top: 10px;
}

.gras-p {
  font-weight: bold;
  color: #0913b1;
}

@media (max-width: 760px) {
  .main-footer {
    width: 100%;
    text-align: center;
  }

  .top-footer {
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    width: 80%;
  }

  .footerText {
    font-size: 12px;
  }

  .logoFooter {
    width: 100%;
    height: auto;
  }
  .liens-footer {
    flex-direction: column;
  }

  .reseaux-sociaux p {
    display: none;
  }

  .tel-hor {
    flex-direction: column;
    padding: 0 5px;
    align-items: center;
  }

  .titres-liens {
    display: flex;
  }

  .titres-liens ul {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .titres-liens li {
    margin-right: 16px;
  }

  .h4-link-footer {
    font-size: 14px;
  }

  .adresse-footer p {
    font-size: 14px;
  }
  .tel-hor p {
    font-size: 14px;
  }

  .tel-hor b {
    font-size: 13px;
  }

  .nav-link {
    font-size: 12px;
    color: gray;
  }
}
