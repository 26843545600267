.history-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #000;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-out; /* Animation d'apparition */
}

.numbers-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 30px;
}

.number-box-years {
  text-align: start;
  margin-right: 35%;
  margin-left: 35%;
  transition: transform 0.4s ease;
}
.number-box-sections {
  text-align: center;
  margin-right: 70%;
  margin-left: 1%;
  transition: transform 0.3s ease;
}
.number-box-members {
  text-align: end;
  margin-right: 20px;
  margin-left: 65%;
  transition: transform 0.3s ease;
}

.number-box:hover {
  transform: scale(1.1); /* Effet zoom sur hover */
}

@keyframes countUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.counter {
  font-size: 5rem;
  color: #ff6d6f; /* Couleur dynamique */
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.b-style {
  font-size: 30px;
  margin-bottom: 10px;
}

.p-historySection {
  display: flex;
  font-size: 1rem;
  color: #fff;
  text-align: justify;
  margin-block-start: 0;
  margin-block-end: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.description {
  font-size: 1rem;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 2s ease 1s forwards; /* Texte qui apparaît avec un léger délai */
}

@media (max-width: 768px) {
  .number-box-years {
    text-align: center;
    align-items: center;
    margin-right: 10%;
    margin-left: 10%;
  }
  .number-box-sections {
    text-align: center;
    margin-right: 10%;
    margin-left: 10%;
  }
  .number-box-members {
    text-align: center;
    margin-right: 10%;
    margin-left: 10%;
  }
}
