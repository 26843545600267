.contact-form {
  max-width: 600px;
  margin: auto;
  padding: 1em;
}
  
.form-row {
  display: flex;
  justify-content: space-between;
}
  
.form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.8em;
  flex: 1;
  margin-right: 1em;
}
  
  
.form-group input,
.form-group textarea {
  color: #666666;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
}
  
.form-group textarea {
  resize: vertical;
  min-height: 100px;
}
  
.bouton-formulaire {
  padding: 0.7em;
  color: #fff;
  background-color: #FF6D6F;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}
  
.bouton-formulaire:hover {
  background-color: #f6595c;
}