.carousel-container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  overflow: hidden; /* Pour masquer les cards en dehors du cadre */
  margin: 0 auto;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 1200px;
  justify-content: center;
}

.card {
  flex: 0 0 33%; /* Chaque card occupe 33% de la largeur */
  margin: 0 10px;
  position: relative;
  opacity: 0.6; /* Cards floutées */
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: #666666;
}

.card img {
  width: 100%;
  height: 100%; /* Taille des images */
  object-fit: cover;
  border-radius: 10px;
}

.active {
  transform: none; /* Zoom léger sur la card du milieu */
  opacity: 1; /* Card bien visible */
}

.active img {
  border: 3px solid #fff; /* Ajoute une bordure pour mettre la card centrale en valeur */
}

.sport-name {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: center;
}

@media (max-width: 768px) {
  .carousel-container {
    width: 100%;
    height: 300px;
  }

  .carousel {
    width: 50%;
    justify-content: center;
  }

  .card {
    flex: 0 0 100%;
    margin: 0 3px; /* Réduit la largeur des cards sur les petits écrans */
  }

  .active img {
    border: 2px solid #fff; /* Réduit la taille de la bordure sur mobile */
  }

  .sport-name {
    font-size: 1rem; /* Réduit la taille du texte */
  }
}
