.header-accueil {
  display: flex;
  width: 100%;
  height: 300px;
  margin-bottom: 80px;
  margin-top: 30px;
  border-radius: 10px;
  justify-content: center;
}

.header-accueil img {
  width: 98%;
  height: 250px;
}

.bouton-accueil {
  padding-left: 16px;
  padding-right: 16px;
  border: 1.5px solid #0913b1;
  color: #fff;
  background: #0913b1;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 65px;
}

.h4-button {
  color: #fff;
  /* margin-top: 20px; */
}
.bouton-accueil:hover {
  font-size: medium;
  transition: transform 0.4s ease-in;
}

.bouton-accueil a,
.btn {
  color: #fff;
  text-decoration: none;
}

.bouton {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.bouton-espace-bouton {
  display: flex;
  justify-content: center;
}

.albums {
  margin-top: 50px;
}

@media (max-width: 1024px) {
  .evenements {
    display: flex;
    flex-direction: wrap;
  }

  .chiffres-cles {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .chiffre2 {
    width: 100%;
  }
}

@media (max-width: 760px) {
  .header-accueil {
    height: 125px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .header-accueil img {
    width: 100%;
    height: 100px;
  }

  .evenements {
    display: flex;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .evenements {
    background-position: center top 10%;
  }
}
