.header-construction {
  width: 100%;
}

.header-construction img {
  width: 100%;
  height: 100px;
}

.contenu-contruction {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contenu-contruction h1 {
  font-size: 54px;
  padding: 0;
  color: #0913b1;
}

.bouton-construction {
  margin-top: 32px;
  font-size: 1rem;
  padding: 12px;
  color: #0913b1;
  background-color: white;
  border: 1px solid #0913b1;
  border-radius: 8px;
}

.p-contruction {
  font-size: 30px;
}
.bouton-construction a,
.btn {
  color: #0913b1;
  text-decoration: none;
}

@media (max-width: 760px) {
  .contenu-contruction h1 {
    font-size: 25px;
    padding: 0;
    color: #0913b1;
  }

  .bouton-construction {
    color: #fff;
    background-color: #0913b1;
  }

  .bouton-construction a,
  .btn {
    color: #fff;
  }
}
