.image-banner {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
  margin-bottom: 30px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

@media (max-width: 768px) {
  .image-banner {
    height: 300px;
  }
}
