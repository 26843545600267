h2 {
  margin-top: 48px;
}

h4 {
  color: #0913b1;
}

.header {
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 32px;
}

.aikido {
  background-image: url("../images/Sports/banniere-aikido.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.athletisme {
  background-image: url("../images/Sports/banniere-athletisme.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.badminton {
  background-image: url("../images/Sports/banniere-badminton.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.basketball {
  background-image: url("../images/Sports/banniere-basketball.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.boxe-thailandaise {
  background-image: url("../images/Sports/banniere-boxethailandaise.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.capoeira {
  background-image: url("../images/Sports/banniere-capoeira.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ecolededanse {
  background-image: url("../images/Sports/banniere-ecoleDeDanse.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ecoledessports {
  background-image: url("../images/Sports/banniere-ecoleDesSports.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 70%;
}

.escrime {
  background-image: url("../images/Sports/banniere-escrime.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 25%;
}

.fitness {
  background-image: url("../images/Sports/banniere-fitness.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.football {
  background-image: url("../images/Sports/banniere-football.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 75%;
}

.gymnastiqueArtistique {
  background-image: url("../images/Sports/banniere-gymArtistique.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gymnastiqueRythmique {
  background-image: url("../images/Sports/banniere-gymRythmique.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.handball {
  background-image: url("../images/Sports/banniere-handball.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.judoJuJitsu {
  background-image: url("../images/Sports/banniere-jujitsu.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
}

.karate {
  background-image: url("../images/Sports/banniere-karate.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.kungFu {
  background-image: url("../images/Sports/banniere-kungFu.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 25%;
}

/* .marcheNordique {
  background-image: url("../images/Sports/banniere-marcheNordique.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.natation {
  background-image: url("../images/Sports/banniere-natation.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.parkour {
  background-image: url("../images/Sports/banniere-parkour.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 65%;
}

/* .petanque {
  background-image: url("../images/Sports/banniere-petanque.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.rugby {
  background-image: url("../images/Sports/banniere-rugby.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.squash {
  background-image: url("../images/Sports/banniere-squash.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
}

.taekwondo {
  background-image: url("../images/Sports/banniere-taekwondo.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 15%;
}

.tennis {
  background-image: url("../images/Sports/banniere-tennis.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tennisDeTable {
  background-image: url("../images/Sports/banniere-tennisDeTable.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.volleyball {
  background-image: url("../images/Sports/banniere-volleyball.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.yoga {
  background-image: url("../images/Sports/banniere-yoga.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 35%;
}

.nom-sport {
  margin-left: 64px;
  font-family: "roboto", sans-serif;
}

.saison {
  margin-right: 64px;
  font-family: "roboto", sans-serif;
}

.infos {
  margin: 32px 64px 32px 64px;
}

.infos h2 {
  color: #b8bcef;
  font-family: "roboto", sans-serif;
}

.infos hr {
  color: #b8bcef;
}

.methodes {
  display: flex;
  justify-content: space-between;
  gap: 20%;
  text-align: justify;
}

.methodes h4 {
  color: #0913b1;
  font-family: "roboto", sans-serif;
  width: 40%;
}

.description-ajout {
  color: #0913b1;
}

.ligne {
  width: 100%;
  height: 2px;
  background-color: #e1e1e1;
  border: none;
}

.ligne-titre {
  width: 100%;
  height: 3px;
  background-color: #b8bcef;
  border: none;
}

.titre-entrainements {
  width: 100%;
  display: flex;
  gap: 10%;
}

.titre-entrainements div {
  width: 100%;
  margin-top: 16px;
  color: #0913b1;
  background-image: url("../images/Sports/ampoule.png");
  background-repeat: no-repeat;
  background-position: left center;
}

.titre-entrainements p {
  padding-top: 1%;
  padding-left: 5%;
}

.entrainement-ligne {
  width: 80%;
  margin-right: 20%;
  display: flex;
  justify-content: start;
  gap: 10%;
}

.entrainement-ligne h4 {
  max-width: 20%;
  min-width: 20%;
  color: #0913b1;
}

.entrainement-ligne p {
  min-width: 25%;
  max-width: 25%;
}

.inscription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-right: 18%;
}

.bloc {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  align-items: flex-start;
  margin-top: 10px;
}

.p-h5-bloc {
  margin-block-end: 5px;
}

.h5-bloc {
  margin-block-end: 0px;
}

.bloc div {
  margin-top: 12px;
}

.h4 {
  min-width: 40%;
}

.inscription-h3 {
  display: flex;
  color: #0913b1;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.lien {
  color: #0913b1;
  text-decoration: underline;
  font-weight: bolder;
  font-size: 14px;
}

.lien-span-h5 {
  font-size: 12px;
  font-weight: bolder;
}
.lien a,
.btn {
  color: #0913b1;
  text-decoration: none;
}

.cotisations {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  margin-top: 25px;
  margin-bottom: 40px;
}

.ligne-cotisations {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  gap: 32px;
  flex-wrap: wrap;
}

.case-cotisation {
  width: 180px;
  min-height: 120px;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #b8bcef;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.prix-cotisation {
  font-size: 25px;
}

.prix-cotisation-gym {
  font-size: 24px;
}
.menu-deroulant {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-deroulant span {
  margin-left: 12px;
  margin-bottom: 4px;
  font-size: larger;
  font-weight: 500;
}

.autres-moyens p {
  color: black;
}

.remise {
  background-image: url("../images/Sports/ampoule.png");
  background-repeat: no-repeat;
  background-position: left;
}

.remise h4 {
  margin-left: 36px;
}

.gras {
  color: #0913b1;
}

.contacts {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 10px;
  justify-content: flex-start;
}

.infos-contact {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}

.contacts h4 {
  width: 30%;
  color: #0913b1;
}

.email {
  color: #0913b1;
  text-decoration: none;
  padding: 16px;
  font-weight: bolder;
}

.i-style {
  font-weight: lighter;
}

.i-style-cotisations {
  font-size: 16px;
}
.label {
  font-weight: bolder;
}
.h4-inscriptionNatation {
  margin-top: 10px;
  color: red;
  text-align: center;
}

.p-inscription {
  color: red;
  font-weight: bold;
}

.h4-inscription {
  display: flex;
  margin-top: 40px;
  color: red;
  text-align: center;
  margin-left: 100px;
}
.h4-message {
  display: flex;
  color: red;
  text-align: start;
  font-size: 18px;
  font-weight: bold;
}

.permanences {
  display: flex;
  margin-top: 40px;
  color: #0913b1;
  text-align: start;
  margin-left: 100px;
  font-weight: bold;
  font-size: 18px;
}

.box-button-sportPages {
  display: flex;
  justify-content: space-between;
  margin: 32px 70px;
}

.button-sportPages {
  display: flex;
  background-color: #0913b1;
  border-radius: 15px;
  align-items: center;
  border-color: #fff;
  box-shadow: #474966 5px 5px 5px 0;
  width: 120px;
  justify-content: center;
  cursor: pointer;
}

.link-button-sportPages {
  text-decoration: none;
  text-align: center;
}

.h4-button-sportPages {
  color: #fff;
}

.b-style-cotisations {
  font-size: 16px;
}

.span-contact {
  margin-bottom: 15px;
}

/**********************

    RESPONSIVE 

**********************/

@media (min-width: 1024px) and (max-width: 1280px) {
  .header {
    font-size: 24px;
  }

  .nom-sport {
    margin-left: 32px;
  }

  .saison {
    margin-right: 32px;
  }

  .infos {
    margin: 16px 32px 16px 32px;
  }

  .methodes {
    display: flex;
    justify-content: space-between;
    gap: 10%;
  }

  .entrainement-ligne {
    width: 100%;
    margin-right: 5%;
  }

  .cotisation {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    height: 30vh;
    font-size: 24px;
  }

  .nom-sport {
    margin-left: 32px;
  }

  .saison {
    margin-right: 32px;
  }

  .infos {
    margin: 16px 32px 16px 32px;
  }

  .methodes {
    display: flex;
    justify-content: space-between;
    gap: 10%;
  }

  .titre-entrainements p {
    padding-left: 10%;
  }

  .entrainement-ligne {
    width: 100%;
    margin-right: 5%;
  }

  .bloc {
    display: block;
  }

  .cotisations {
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .header {
    height: 20vh;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: first center;
    justify-content: center;
  }

  .nom-sport {
    margin-left: 16px;
    margin-block-end: 2px;
  }

  .h1-mon-sport {
    margin-block-start: 10px;
    margin-block-end: 10px;
    font-size: 26px;
  }

  .saison {
    margin-right: 16px;
    margin-block-start: 2px;
  }

  .h2-saisons {
    margin-top: 0;
  }

  .infos {
    margin: 54px 12px 8px 12px;
    text-align: justify;
    padding: 4px;
  }

  .methodes {
    display: block;
    margin-bottom: 48px;
    padding: 5px;
  }

  .methodes h4 {
    width: 100%;
    margin-block-start: 20px;
    margin-block-end: 20px;
    text-align: center;
  }

  .titre-entrainements {
    width: 100%;
    display: block;
    gap: 0%;
  }

  .titre-entrainements p {
    padding-left: 15%;
  }

  .entrainement-ligne {
    width: 100%;
    margin-right: 0%;
    display: block;
  }

  .entrainement-ligne h4 {
    width: 100%;
  }

  .entrainement-ligne h4 {
    max-width: 100%;
    min-width: 100%;
    color: #0913b1;
  }

  .entrainement-ligne p {
    min-width: 100%;
    max-width: 100%;
  }

  .entrainements {
    margin: 5px 5px;
    padding: 5px 5px;
  }

  .case {
    margin-right: 0%;
  }

  .bloc {
    display: block;
    margin: 0 10px 10px;
  }

  .inscription {
    display: block;
    margin: 0;
    padding: 5px;
  }

  .inscription-h3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .cotisations {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .ligne-cotisations {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contacts {
    display: flex;
    flex-direction: column;
  }

  .contacts h4 {
    font-size: 15px;
    width: 100%;
    text-align: center;
    color: #0913b1;
  }

  .h2 {
    font-size: 22px;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }

  .ul-paiement {
    padding-inline-start: 25px;
    padding-inline-end: 10px;
  }

  .box-button-sportPages {
    margin-right: 15px;
    margin-left: 15px;
  }

  .infos-contact {
    align-items: center;
  }

  .span-contact {
    margin-bottom: 15px;
  }

  .permanences {
    text-align: center;
    font-size: 16px;
    margin-left: 15px;
  }
}
