/* .body-admin {
  width: 100%;
  margin: 50px 20px 20px 100px;
  min-height: 600px;
} */

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 60%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #d2cfcf;
}

.form-group {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

/* .h1-admin {
  display: flex;
  text-align: center;
  color: #333;
  background-color: #ccc;
  border-radius: 5px;
  width: 50%;
  justify-content: center;
  margin: 50px 10px 40px 200px;
} */

/* .box-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 20px;
  margin-right: 100px;
} */

.label-admin {
  width: 20%;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.input-admin[type="text"],
.input-admin[type="date"],
.textarea-admin,
.select-admin {
  width: 75%;
  padding: 8px;
  margin: 0 0 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

.textarea-admin {
  resize: vertical;
}

.button-admin {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #0913b1;
  cursor: pointer;
  font-size: 16px;
}

.button-admin:hover {
  background-color: #0913b1;
}

.button-admin[type="button"] {
  background-color: #28a745;
}

.button-admin[type="button"]:nth-child(2) {
  background-color: #666666;
}

.button-admin[type="button"]:nth-child(3) {
  background-color: #dc3545;
}

.button-admin[type="button"]:hover {
  background-color: #218838;
}

.button-admin[type="button"]:nth-child(2):hover {
  background-color: #e0a800;
}

.button-admin[type="button"]:nth-child(3):hover {
  background-color: #c82333;
}
